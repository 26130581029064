.page-header-panel {
    /* background: #0079b8; */
    padding: 1.5em 0;
}

input.text-white::placeholder {
    opacity: 0.3;
    font-style: italic;
    font-weight: 500;
}
.spinner-jetts {
    color: #de2341;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.parentDisable {
    position: fixed;
    top: 0;
    left: 0;
    background: #666;
    opacity: 0.7;
    z-index: 998;
    height: 100%;
    width: 100%;
}

.center-align {
    text-align: center;
}
.color-red {
    color: #de2341;
}
.btn-align {
    margin-top: 1px;
}

.big-checkbox {
    transform: scale(1.5);
}

.big-checkbox:focus {
    box-shadow: none;
    outline: none;
}
