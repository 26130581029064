.modal-width {
    width: 900px !important;
}

.modal-dialog {
    max-width: 900px !important;
}

.modal-height {
    min-height: 400px;
}
.min-width {
    min-width: 400px;
    max-width: 400px;
    height: 300px;
}

.align-center-no-photo {
    color: #de2341;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 300px;
    text-align: center;
  }